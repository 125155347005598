/* eslint-disable import/order */
import React, { useState } from 'react'

// Third Party
import Helmet from 'react-helmet'

import Theme from '../styles/theme'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import '../styles/all.css'
import '../utils/fonts/stylesheet.css'

const Layout = ({ children }) => {
  const [waardeIsHidden, setWaardeHidden] = useState(false)

  return (
    <Theme>
      <Header />
      <>{children}</>
      <Footer
        waardeIsHidden={waardeIsHidden}
        setWaardeHidden={setWaardeHidden}
      />
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="356b3042-df95-49c1-ba24-72f7e34ee34c"
          type="text/javascript"
          async
        />
        <script type="text/javascript">
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2345509,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
        <script
          id="ldbtscrpt"
          src="https://d2qh0sy46xxq25.cloudfront.net/leadbot.min.js?apiKey=c52eb7a84cc041f4d8696c86"
        />
      </Helmet>
    </Theme>
  )
}

export default Layout
